<template>
  <form
      id="new-address-form"
      role="form"
      method="POST"
      novalidate
      class="custom-validation"
      data-toggle="validator"
      @submit="addAddress"
  >
    <div class="loader" v-show="loading">LOADING...</div>
<!--    {{address}}-->
    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-name-newadd">
          {{ $trans("form-input.ragione-sociale") }}
          <sup>*</sup>
        </label>
        <input
            type="text"
            class="form-control"
            id="checkout-name-newadd"
            name="ragione_soc"
            v-model="ragione_soc"
            required
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>

      <div class="form-group col-md-6">
        <label for="checkout-country-newadd"
        >{{ $trans("form-input.nazione") }} <sup>*</sup></label
        >
        <select
            id="checkout-country-newadd"
            class="form-control"
            name="nazione"
            v-model="nazione"
        >
          <option v-for="item in nations">{{ item.nazione || item }}</option>
        </select>
        <div v-if="nazione && isBlackListed">
          <div class="vue-field-error">
            {{ $trans("form-input.nazione-blacklist") }}
          </div>
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>

    <div class="form-row">

      <div class="form-group col-md-9">
        <label for="checkout-street-address-newadd"
        >{{ $trans("form-input.indirizzo") }} <sup>*</sup></label
        >
        <input
            type="text"
            class="form-control"
            id="checkout-street-address-newadd"
            name="indirizzo"
            v-model="indirizzo"
            required
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
        <div
            class="invalid-feedback"
            style="display: block"
            v-if="!isAddressValid"
        >
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>

      <div class="form-group col-md-3">
        <label>{{ $trans("form-input.cap") }} <sup>*</sup></label>
        <div class="select2-wrapper" style="position:relative;">
          <input
              :id="'checkout-postcode-newadd-' + this.addressId"
              v-model="local_obj.zip.value"
              name="cap"
              class="form-control"
              type="text"
              maxlength="10"
              onkeydown="regexInput(event, new RegExp('^[a-zA-Z0-9 -]+$'))"
              @input="typingAction('zip')"
              @focusin="focusAction('zip', true)"
              @focusout="focusAction('zip', false)"
              :disabled="local_obj.zip.disabled"
              required
              autocomplete="off"
          />
          <div class="loader_2" v-show="local_obj.zip.loading"></div>
          <div class="suggestions" v-show="suggestions.zip.visible">
            <div
                v-for="(item, index) in suggestions.zip.list"
                @click.stop="selectAction('zip', item)"
                :key="index"
            >
              {{ item.cap }}
            </div>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>

    <div class="form-row">

      <div class="form-group" :class="[ isItalia ? 'col-md-9' : 'col-md-12' ]">
        <label>{{ $trans("form-input.city") }} <sup>*</sup></label>
        <div class="select2-wrapper" style="position:relative;">
          <input
              :id="'checkout-city-newadd-' + this.addressId"
              v-model="local_obj.town.value"
              name="citta"
              class="form-control"
              type="text"
              @input="typingAction('town')"
              @focusin="focusAction('town', true)"
              @focusout="focusAction('town', false)"
              :disabled="local_obj.town.disabled"
              required
              autocomplete="off"
          />
          <div class="loader_2" v-show="local_obj.town.loading"></div>
          <div class="suggestions" v-show="suggestions.town.visible">
            <div
                v-for="(item, index) in suggestions.town.list"
                @click.stop="selectAction('town', item)"
                :key="index"
            >
              {{ item.citta }}
            </div>
          </div>
        </div>
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>

      <div class="form-group col-md-3" v-if="isItalia">
        <label for="checkout-state-newadd">
          {{ $trans("form-input.provincia") }}
          <sup>*</sup>
        </label>
        <input
            type="text"
            class="form-control"
            id="checkout-state-newadd"
            maxlength="2"
            name="provincia"
            v-model="provincia"
            :required="isItalia"
        />
        <div class="invalid-feedback">
          {{ $trans("form-input.campo-obbligatorio") }}
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-md-6">
        <label for="checkout-tel-newadd">{{
            $trans("form-input.telefono")
          }}</label>
        <input
            type="tel"
            class="form-control"
            id="checkout-tel-newadd"
            name="telefono"
            v-model="telefono"
            maxlength="15"
        />
      </div>
      <div class="form-group col-md-6">
        <label for="checkout-cell-newadd">{{
            $trans("form-input.cellulare")
          }}</label>
        <input
            type="tel"
            class="form-control"
            id="checkout-cell-newadd"
            name="cellulare"
            v-model="cellulare"
            maxlength="15"
        />
      </div>
    </div>

    <button type="submit" class="btn btn-primary" v-bind:disabled="!isValid">
      {{ $trans("form-input.salva-indirizzo") }}
    </button>
  </form>
</template>

<script>
import axios from "axios";
import lodash from 'lodash';
import toastr from "toastr";

export default {
  name: "new-address",
  props: {
    addressId: {
      required: false,
      type: Number,
      default: 0,
    },
    address: {
      required: false,
      type: Object,
      default: null,
    },
    fromCheckout: {
      required: false,
      type: Boolean,
      default: false,
    },
    fromOrder: Array,
    reload: {
      required: false,
      type: Boolean,
      default: false
    },
    countries: Array,
    provinces: Array,
  },
  data() {
    return {
      isMounted: false,
      loading: true,
      ragione_soc: null,
      nazione: '',
      indirizzo: null,
      cap: null,
      citta: null,
      provincia: null,
      telefono: null,
      cellulare: null,
      cities: [],
      loadingCities: false,
      loadingAddress: false,
      nations: [],
      isItalia: false,

      // nuova logica
      local_obj: {
        zip: {
          value: null,
          disabled: false,
          loading: false,
        },
        town: {
          value: null,
          disabled: false,
          loading: false,
        },
      },
      suggestions: {
        zip: {
          visible: false,
          loading: false,
          timer: null,
          list: [],
        },
        town: {
          visible: false,
          loading: false,
          timer: null,
          list: [],
        },
      },
    };
  },
  mounted() {
    window.regexInput = this.regexInput;
    window.setLoading = this.setLoading;
    this.nations = this.countries;
    console.log('gino', this.address, this.countries, this.provinces)
    // $("#e6").select2('data', {id: '1049', text: 'MyLabel'});

    if (this.address != null) {
      this.loadingAddress = true;
      this.parseAddress();
    }

    if (this.fromOrder != null) {
      this.loadingAddress = true;
      this.parseAddressFromOrder(this.fromOrder);
    }

    $(document).ajaxStart(() => this.loading = true);
    $(document).ajaxStop(() => this.loading = false);
    setTimeout(() => {
      this.setLoading(false);
      this.isMounted = true;
    }, 1000);
  },
  methods: {
    focusAction(source, state) {
      if(state) {
        this.suggestions[source].visible = this.suggestions[source].list.length > 0;
      } else {
        setTimeout(() => this.suggestions[source].visible = false,150);
      }
    },
    selectAction(source, item) {
      console.log('clicked')
      const key = source === 'zip' ? 'cap' : 'citta';
      this.local_obj[source].value = item[key];
      this.suggestions[source].visible = false;
      this.suggestions[source].list = this.suggestions[source].list.filter(r => r[key] === item[key]);
    },
    searchAction(source) {
      const value = this.local_obj[source].value || '';
      const country = this.nazione || 'IT';

      this.suggestions[source].list = [];
      this.local_obj[source].disabled = this.local_obj[source].loading = true;

      switch(source) {
        case 'zip':
          axios
              .get(`/api/v1/cap?q=${value}&nation=${country}`)
              .then((res) => {
                this.suggestions[source].list = res.data.data;
                this.suggestions[source].visible = true;
                console.log('dimo', this.suggestions[source].list);

                // if (!this.loadingAddress) {
                //   if (this.cities.length === 1) {
                //     this.citta = this.cities[0].citta;
                //     this.provincia = this.cities[0].provincia;
                //   } else {
                //     this.citta = null;
                //     this.provincia = null;
                //   }
                // } else {
                //   this.loadingAddress = false;
                // }
              })
              .finally(() => this.local_obj[source].disabled = this.local_obj[source].loading = false);
          break;
        case 'town':
          const cap = `&cap=${this.local_obj.zip.value}` || '';
          axios
              .get(`/api/v1/city?q=${value}${cap}&nation=${country}`)
              .then((res) => {
                this.suggestions[source].list = res.data.data;
                this.suggestions[source].visible = true;
                console.log('dimo', this.suggestions[source].list);

                // if (!this.loadingAddress) {
                //   if (this.cities.length === 1) {
                //     this.citta = this.cities[0].citta;
                //     this.provincia = this.cities[0].provincia;
                //   } else {
                //     this.citta = null;
                //     this.provincia = null;
                //   }
                // } else {
                //   this.loadingAddress = false;
                // }
              })
              .finally(() => this.local_obj[source].disabled = this.local_obj[source].loading = false);
          // this.suggestions.town.loading = true;
          // this.suggestions.town.list = [];
          // LocationsApi
          //     .getLocationList({key: source, search: value, country})
          //     .then(r => {
          //       this.suggestions.town.list = r.msg;
          //       this.suggestions.town.visible = true;
          //     })
          //     .catch(err => this.$message.error(err.response.data.message))
          //     .finally(() => this.suggestions.town.loading = false);
          break;
      }
    },
    typingAction(source) {
      const value = this.local_obj[source].value || '';
      switch(source) {
        case 'zip':
          if(value.length >= 3) {
            clearTimeout(this.suggestions[source].timer);
            this.suggestions[source].timer = setTimeout(() => {
              this.searchAction('zip');
            }, 800);
          }
          break;
        case 'town':
          if(value.length >= 3) {
            clearTimeout(this.suggestions[source].timer);
            this.suggestions[source].timer = setTimeout(() => {
              this.searchAction('town');
            }, 800);
          }
          break;
      }
    },
    setLoading(flag){
      this.loading = flag;
    },
    getCap() {
      const nation = !this.address || Object.keys(this.address).length === 0 //this.nazione != document.querySelector('#checkout-country-newadd').value
          ? this.nazione
          : document.querySelector('#checkout-country-newadd').value;
      console.log('loading', nation)

      if(!nation) return;

      this.selectBox.select2({
        minimumInputLength: 3,
        language: "it",
        width: "100%",
        ajax: {
          url: "/api/v1/cap",
          dataType: "json",
          data: function (params) {
            return {
              q: params.term,
              nation
            };
          },
          processResults: function (data) {
            console.log('end loading')
            return {
              results: data.data.map((el) => {
                return {
                  id: el.cap,
                  text: el.cap,
                };
              }),
            };
          },
        },
      });

      // const $option = $(`<option selected>${this.selectBox.attr('data-value')}</option>`).val(this.selectBox.attr('data-value'));
      // this.selectBox.append($option).trigger('change');
    },
    loadCities: function () {
      let cap = this.cap || this.address || this.address.cap || document.querySelector('#estero-cap').value;
      cap = typeof cap === "string" ? cap : cap.cap;
      this.loadingCities = true;
      console.log('TEST', this.cap, document.querySelector('#estero-cap').value, this.address)
      axios
          .get("/api/v1/city?q=" + cap + "&nation=" + this.nazione)
          .then((res) => {
            this.cities = res.data.data;

            if (!this.loadingAddress) {
              if (this.cities.length === 1) {
                this.citta = this.cities[0].citta;
                this.provincia = this.cities[0].provincia;
              } else {
                this.citta = null;
                this.provincia = null;
              }
            } else {
              this.loadingAddress = false;
            }
          })
          .finally(() => {
            this.loadingCities = false;
          });
    },
    parseAddressFromOrder(order) {
      console.log(order);

    },
    parseAddress: function () {
      const capData = {
        id: this.address.cap,
        text: this.address.cap,
      };

      const option = new Option(capData.text, capData.id, true, true);
      $("#checkout-postcode-newadd-" + this.addressId)
          .append(option)
          .trigger("change");

      this.ragione_soc = this.address.ragione_soc;
      this.nazione = this.address.nazione;
      this.indirizzo = this.address.indirizzo;
      this.cap = this.address.cap;
      this.citta = this.address.citta;
      this.provincia = this.address.provincia;
      this.telefono = this.address.telefono;
      this.cellulare = this.address.cellulare;
      this.local_obj.zip.value = this.cap;
      this.local_obj.town.value = this.citta;

      // $("#checkout-postcode-newadd-" + this.addressId).trigger({
      //   type: "select2:select",
      //   params: {
      //     data: capData,
      //   },
      // });
    },
    addAddress: function (e) {
      e.preventDefault();
      axios
          .post("/api/v1/client/address/add", {
            address_id: this.address != null ? this.address.id : null,
            ragione_soc: this.ragione_soc,
            indirizzo: this.indirizzo ? this.indirizzo.trim() : this.indirizzo,
            nazione: this.nazione,
            cap: this.cap || this.local_obj.zip.value,
            citta: this.citta || this.local_obj.town.value,
            provincia: this.provincia,
            telefono: this.telefono,
            cellulare: this.cellulare,
          })
          .then((res) => {
            if (this.reload) {
              window.location.reload();
            }
            this.$emit("newAddress", res);
          })
          .catch((error) => {
            alert(error.response.data.errors.join());
          });
    },
    regexInput(event, pattern, callback = null) {
      const key = event.key;

      const ctrlKey = event.ctrlKey || event.metaKey; // ctrlKey per Windows, metaKey per Mac
      const allowedKeys = ["Backspace", "ArrowLeft", "ArrowRight", "Delete", "Tab", "Enter"];
      if (ctrlKey || allowedKeys.includes(key)) {
        return true;
      }

      if (!pattern.test(key)) {
        event.preventDefault();
        return false;
      }
      if (callback) {
        callback();
      }
      return true;
    }
  },
  computed: {
    isBlackListed: function () {
      const found = this.nations.find(el => el.nazione === this.nazione);
      return found ? found.blacklistEcommerce : false;
    },
    selectBox: function () {
      return $("#checkout-postcode-newadd-" + this.addressId);
    },
    isValid: function () {
      return (
          this.ragione_soc &&
          this.indirizzo &&
          (this.cap || this.local_obj.zip.value) &&
          (this.citta || this.local_obj.town.value) &&
          (
              this.provincia || (this.nazione !== 'ITALIA' && this.nazione !== '')
          ) &&
          this.nazione &&
          this.isAddressValid
      );
    },
    isAddressValid: function () {
      if (!this.indirizzo) {
        return true;
      }
      return /\d/.test(this.indirizzo) && this.indirizzo.trim().length > 3 && (this.indirizzo.trim().match(/\s/g) || []).length > 0;
      // return /^(?=[^a-z\n]*[a-z])[A-Za-z.-.\s.\\..,°.-]*[0-9][A-Za-z.-.\s.\\..,°.-]*$/.test(this.indirizzo);
      // let text = !this.indirizzo.match(/\D/g)
      //     ? ''
      //     : this.indirizzo.match(/\D/g).join('').trim().replace(/[^a-z]/gi, '');
      // let digits = this.indirizzo.replace(/\D/g, "");
      // return !text || !digits;
    },
  },
  watch: {
    nazione: function (v) {
      this.isItalia = (v ? v.toLowerCase().startsWith('it') : '') || !v || v === '-';
      if(this.isMounted) {
        this.local_obj.town.value = this.local_obj.zip.value = this.provincia = null;
      }
      console.log(v, this.isItalia);
      // if (this.isItalia) {
      //   this.getCap();
      //   this.cap = '';
      // }
    },
    cap: function () {
      // this.loadCities();
    },
    citta: function (v) {
      // const filtered = this.cities.filter((el) => {
      //   return el.citta === this.citta;
      // });
      // if (filtered.length > 0) {
      //   this.provincia = filtered[0].provincia;
      // }
      // setTimeout(() => {
      //   if(v) {
      //     this.loading = false;
      //   }
      // }, 2000);
    },
  },
};
</script>
<style>
.suggestions {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  width: 100%;
  max-height: 180px;
  overflow: hidden auto;
  padding: 0 1px;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
}
.suggestions > div { padding: 10px; font-size: 18px; user-select: none; cursor: pointer; }
.suggestions > div:hover { background-color: rgba(227, 140, 143, 0.89); }
.loader_2 {
  position: absolute;
  top: 11px;
  right: 10px;
  width: 23px;
  background-size: 3px 3px, auto;
  -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 3px),#000 0);
}
</style>
