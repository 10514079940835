import Swal from 'sweetalert2';

export function CustomSwal(options) {
    const defaultOptions = {
        confirmButtonColor: '#28a745',
        cancelButtonColor: '#d2d1d2',
        confirmButtonText: 'Si',
        cancelButtonText: 'No',
        reverseButtons: true,
        focusConfirm: true,
        allowEnterKey: false,
        customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-black'
        },
        buttonsStyling: false
    };
    const merged = Object.assign(defaultOptions, options);

    return Swal.fire(merged);
}

export function checkFiscalCode(cf) {
    if ( ! /^[0-9A-Z]{16}$/.test(cf) ) {
        return false;
    }

    let s = 0;
    const even_map = "BAFHJNPRTVCESULDGIMOQKWZYX";

    for (let i = 0; i < 15; i++) {
        const c = cf[i];
        let n = 0;

        if ("0" <= c && c <= "9") {
            n = c.charCodeAt(0) - "0".charCodeAt(0);
        } else {
            n = c.charCodeAt(0) - "A".charCodeAt(0);
        }

        if ( (i & 1) === 0) {
            n = even_map.charCodeAt(n) - "A".charCodeAt(0);
        }

        s += n;
    }

    return s % 26 + "A".charCodeAt(0) === cf.charCodeAt(15);
}

export function getSediRitiro(response, sortArray = ['K','L','H','P','B','E','G','T','Z','S']) {
    let res = response.map(r => {
        r.splits = r.indirizzo.split(/, (\d{5}) /i);
        if(r.id_sede === 'G') {
            r.nome_sede = "Calcinato";
        } else if(r.id_sede === 'E') {
            r.nome_sede = "Busto Arsizio";
        }
        return r;
    });
    console.log('cimone2', res);
    res = res.filter(r => !['R', 'A', 'J'].includes(r.id_sede)); // tolgo autore
    res.sort((a, b) => sortArray.indexOf(a.id_sede) - sortArray.indexOf(b.id_sede));
    return res;
}
