<template>
  <div class="mt-4">
    <div class="block-header block-header--has-breadcrumb block-header--has-title">
      <div class="container">
        <div class="block-header__body">
          <h1 class="block-header__title">
            {{ $trans('autodemolizione-torino.rottamazione-auto') }}
          </h1>
        </div>
      </div>
    </div>
    <!--<div v-if="firstStep"> -->
    <div class="card mt-2">
      <div class="card-body card-body--padding--2">
        <div class="row">
          <div class="col-lg-6">
            <div>
              <h5>{{ $trans("autodemolizione-torino.cerca-targa") }}</h5>
              <div class="input-group">
                <input v-model="targa" :placeholder="targa_text" class="input-form-rottama form-control" maxlength="7"
                       type="text">
                <div class="input-group-append">
                  <button style="border-bottom-right-radius: 2px; border-top-right-radius: 2px;" @click="cercaTarga()">
                    {{ $trans("common.cerca") }}
                  </button>
                </div>
                <br>

              </div>
              <div
                  class="g-recaptcha text-center mt-2"
                  style="margin: auto"
                  v-bind:data-sitekey="recaptchaSiteKey"
              ></div>
            </div>
            <div
                class="margin-top-custom"
            >
              <h5 class="custom-space-h5">{{ $trans("autodemolizione-torino.inserimento-manuale") }}</h5>
              <select
                  id="brand_select"
                  v-model="selectedBrand"
                  aria-label="Seleziona Marca"
                  class="form-control form-control-select2"
                  name="brand_id"
                  required
                  v-on:change="changeBrand(selectedBrand)"
              >
                <option :value="null" selected>
                  {{ $trans("filter-model.sel-marca") }}
                </option>
                <option
                    v-for="brand in brands"
                    v-bind:key="brand.id"
                    :value="brand.id"
                >
                  {{ brand.label }}
                </option>
              </select>
              <div
                  v-if="showErrors && !selectedBrand"
                  class="text-danger text-left pl-1"
              >
                {{ $trans("filter-model.compila-marca") }}
              </div>
            </div>
            <div
                class="margin-top-custom"
            >
              <select
                  id="model_select"
                  v-model="selectedModel"
                  :disabled="disableSelect.model"
                  aria-label="Seleziona Modello"
                  class="form-control form-control-select2"
                  name="model_id"
                  required
              >
                <option :value="null" selected>
                  {{ $trans("filter-model.sel-modello") }}
                </option>
                <option
                    v-for="model in models"
                    v-bind:key="model.id"
                    :value="model.id"
                >
                  {{ model.label }}
                </option>
              </select>
              <div
                  v-if="showErrors && !selectedModel"
                  class="text-danger text-left pl-1"
              >
                {{ $trans("filter-model.compila-modello") }}
              </div>
            </div>
            <div v-if="firstStep"
                 class="margin-top-custom"
            >
              <select
                  v-if="firstStep"
                  id="date_select"
                  v-model="selectedDate"
                  :disabled="disableSelect.year"
                  aria-label="Scegli l'anno"
                  class="form-control form-control-select2"
                  name="date_id"
                  required
              >
                <option :value="null" selected>
                  {{ $trans("autodemolizione-torino.seleziona-anno") }}
                </option>
                <option
                    v-for="year in years"
                    v-bind:key="year"
                    :value="year"
                >
                  {{ year }}
                </option>
              </select>
              <div
                  v-if="showErrors && !selectedModel"
                  class="text-danger text-left pl-1"
              >
                {{ $trans("filter-model.compila-modello") }}
              </div>
            </div>
            <!--<div class="col-12 text-right pt-3 button-div">
              <button class="btn btn-primary btn-lg" :disabled="disableNext" @click="nextStep()">Avanti</button>
            </div>-->
          </div>
          <div class="col-lg-6 pt-4">
            <p style="font-size: 20px;">
              <span v-html="formatHtml('autodemolizione-torino.intro-parte1')"></span>
              <strong v-html="formatHtml('autodemolizione-torino.intro-parte2')"></strong>
            </p>

            <p style="font-size: 20px;">
              <span v-html="formatHtml('autodemolizione-torino.con')"></span>
              <strong v-html="formatHtml('autodemolizione-torino.nome-azienda')"></strong>
              <span v-html="formatHtml('autodemolizione-torino.descrizione-parte1')"></span>
              <strong v-html="formatHtml('autodemolizione-torino.descrizione-grassetto1')"></strong>
              <span v-html="formatHtml('autodemolizione-torino.descrizione-parte2')"></span>
              <strong v-html="formatHtml('autodemolizione-torino.descrizione-grassetto2')"></strong>
              <span v-html="formatHtml('autodemolizione-torino.descrizione-parte3')"></span>
            </p>
            <p style="font-size:30px;font-weight:bold">Tel. <a href="tel:0306872037">0306872037</a></p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="!firstStep"> -->
    <div class="card mt-4">
      <div class="card-body card-body--padding--2">
        <div>
          <h5>{{ $trans("autodemolizione-torino.dati-contatto") }}</h5>

          <input v-model="mail" class="input-form-rottama form-control" placeholder="E-mail" type="email"
                 @blur="errorMail = false">
          <p v-if="errorMail" class="error-message">{{ $trans("autodemolizione-torino.errore-mail") }}</p>

          <input v-model="phone" class="input-form-rottama form-control" placeholder="Telefono" type="text">
          <p v-if="errorPhone" class="error-message">{{ $trans("autodemolizione-torino.errore-phone") }}</p>

          <input v-model="town" class="input-form-rottama form-control" placeholder="Città di ritiro" type="text">

          <input v-model="area" class="input-form-rottama form-control" maxlength="2" placeholder="Provincia"
                 type="text">

          <textarea v-model="textMessage" class="textarea-form-rottama form-control" placeholder="Messaggio"></textarea>

          <label>
            <input v-model="checkboxModel" type="checkbox" required>
            Dichiaro di aver dato lettura ed accettare la
            <a href="/informativa-trattamento-dati-personali-utenti" target="_blank">Privacy Policy<sup>*</sup></a>
          </label>
          <div class="col-12 text-right pt-3">
            <button :disabled="disabilitateSend" class="btn btn-primary btn-lg" @click="nextStep()">Invia
              <span v-if="isLoading" aria-hidden="true" class="spinner-border spinner-border-sm" role="status"></span>
            </button>
          </div>
          <div v-if="sendSuccess" class="col-12 text-center pt-3">
            <div class="alert alert-success mb-3 alert-dismissible">
              {{ $trans("autodemolizione-torino.mail-success") }}
            </div>
            <!-- <p class="success-message">Il messaggio è stato inviato con successo e verrà elaborato da un nostro operatore</p> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import toastr from "toastr";
import {CustomSwal} from "../utils";

export default {
  name: 'rottamaGratis',
  props: {
    recaptchaSiteKey: String,
    referrer: String,
  },
  data() {
    return {
      brands: [],
      models: [],
      years: [],
      selectedBrand: null,
      selectedModel: null,
      selectedDate: null,
      reloadingData: false,
      disableSelect: {
        model: true,
        year: true
      },
      showErrors: false,
      firstStep: true,
      brandName: '',
      carValue: '',
      targa: '',
      mail: '',
      phone: '',
      town: '',
      area: '',
      textMessage: '',
      errorMail: false,
      errorPhone: false,
      idCaricamento: '',
      checkboxModel: false,
      disabilitateSend: true,
      sendSuccess: false,
      carValue1: '',
      carValue2: '',
      quoteMessage: false,
      maxYear: '',
      higherValue: '',
      isLoading: false,
    };
  },
  mounted() {
    var self = this;

    $("#brand_select").on("change", function () {
      self.selectedBrand = this.value;
      self.$emit("changeBrand", this.value);
    });
    $("#model_select").on("change", function () {
      self.selectedModel = this.value;
      self.$emit("changeModel", this.value);
    });
    $("#date_select").on("change", function () {
      self.selectedDate = this.value;
      self.$emit("changeYear", this.value);
    });
    self.$on("changeBrand", function (data) {
      self.loadModels(data);
    });
    self.$on("changeModel", function (data) {
      self.loadYears(self.selectedBrand, data);
    });
  },
  computed: {
    titolo: function () {
      return this.$trans('autodemolizione-torino.rottamazione-auto');
    },
    disableNext: function () {
      return !(this.selectedBrand && this.selectedModel && this.selectedDate);
    },
    targa_text: function () {
      return this.$trans("cart.targa");
    }
  },
  created() {
    this.loadBrands();
    this.$set(this, "models", []);
    this.$set(this, "years", []);
  },
  watch: {
    mail: function () {
      this.check_form();
    },
    phone: function () {
      this.check_form();
    },
    town: function () {
      this.check_form();
    },
    area: function () {
      this.check_form();
    },
    checkboxModel: function () {
      this.check_form();
    },
  },
  methods: {
    check_form() {
      this.disabilitateSend = !((this.mail.trim() || this.phone.trim()) && this.checkboxModel);
    },
    formatHtml(key) {
      return this.$trans(key);
    },
    loadBrands: function () {
      axios.get('/api/v1/quattroruote/brands')
          .then(response => {
            this.$set(this, 'brands', response.data.data.map(({IDMAR, DSMAR}) => (
                {
                  id: IDMAR,
                  label: DSMAR
                }
            )));
            if (this.reloadingData) {
              this.$emit("changeBrand", this.selectedBrand);
            } else {
              this.$set(this, "selectedModel", null);
            }
          })
          .catch(error => {
            console.error(error);
            toastr.error('Errore generico', 'Errore');
          });
    },
    loadModels: function (brandId) {
      this.$set(this, "models", []);
      this.$set(this, "selectedModel", null);
      if (brandId) {
        axios.get('/api/v1/quattroruote/models', {
          params: {
            idMar: brandId
          }
        })
            .then(response => {
              this.$set(this, 'models', response.data.data.map(({IDMOD, DSMOD}) => (
                  {
                    id: IDMOD,
                    label: DSMOD
                  }
              )));
              this.disableSelect.model = false;
              if (this.reloadingData) {
                this.$emit("changeModel", this.selectedModel);
              } else {
                this.$set(this, "selectedDate", null);
              }
            })
            .catch(error => {
              console.error(error);
              toastr.error('Errore generico', 'Errore');
            });
      }
    },
    loadYears: function (brandId, modelId) {
      this.$set(this, "years", []);
      if (!this.reloadingData) {
        this.$set(this, "selectedDate", null);
      }
      if (modelId && brandId) {
        axios.get('/api/v1/quattroruote/years', {
          params: {
            idMar: brandId,
            idMod: modelId
          }
        })
            .then(response => {
              this.$set(this, 'years', response.data.data)
              this.disableSelect.year = false;
            })
            .catch(error => {
              console.error(error);
              toastr.error('Errore generico', 'Errore');
            });
      }
    },
    nextStep: function () {
      if (this.phone || this.validEmail(this.mail)) {
        this.insertDb();
        //this.checkValutazione();
      } else {
        if (!this.validEmail(this.mail)) {
          this.errorMail = true;
        }
        if (!this.phone) {
          this.errorPhone = true;
        }

      }
    },
    insertDb: function () {
      //console.log(this.selectedDate, this.selectedBrand, this.selectedModel)
      if (this.selectedDate && this.selectedBrand && this.selectedModel) {
        this.isLoading = true;
        const brand = this.brands.find(el => el.id === this.selectedBrand);
        this.brandName = brand ? brand.label : '';

        const model = this.models.find(el => el.id === this.selectedModel);
        this.modelName = model ? model.label : '';

        axios.post('/api/v1/rottamagratis/request', {
          idMar: this.selectedBrand,
          idMod: this.selectedModel,
          dsMar: this.brandName,
          dsMod: this.modelName,
          year: this.selectedDate,
          referrer: this.referrer,
          targa: this.targa,
        })
            .then(response => {
              this.idCaricamento = response.data.data[0];
              this.sendForm();
            })
            .catch(error => {
              console.log(error.response.data.errors.join());
              this.isLoading = false;
            })
      } else {
        toastr.error('Dati mancanti', 'Errore');
      }
    },
    checkValutazione: function () {
      if (this.selectedBrand && this.selectedModel) {
        axios.get('/api/v1/rottamagratis/evaluate', {
          params: {
            idMar: this.selectedBrand,
            idMod: this.selectedModel
          }
        })
            .then(response => {
              this.carValue = response.data.data[0];
              if (this.carValue) {
                if (this.carValue != 'gratis') {
                  this.carValue1 = this.carValue.substring(0, 3) + ',00€';
                  this.carValue2 = this.carValue.substring(4) + ',00€';
                  this.quoteMessage = true;
                } else {
                  this.quoteMessage = false;
                }
              }


            })
            .catch(error => {
              if (error.response.status === 404) {
                this.carValue = null;
                this.maxYear = this.years.slice(-1);
                console.log(this.maxYear[0]);
                if (this.maxYear[0] > 2004) {
                  this.higherValue = true;
                } else {
                  this.higherValue = false;
                }
              }
            })
            .finally(() => this.firstStep = false);
      }
    },
    /*sendForm: function () {
      //this.validEmail(this.mail);
      //console.log(this.validEmail(this.mail));
      if(this.phone || this.validEmail(this.mail)) {
        axios.put(`/api/v1/rottamagratis/request/${this.idCaricamento}`, {
          email: this.mail,
          telefono: this.phone,
          citta: this.town,
          provincia: this.area,
          message: this.textMessage,
          referrer: this.referrer
        })
            .then(() => {
              this.mail = '';
              this.phone = '';
              this.town = '';
              this.area = '';
              this.textMessage = '';
              this.checkboxModel = false;
              this.sendSuccess = true;
              window.trackFormSubmit('rottama-gratis-step2', window.location.href)
            })
            .catch(error => {
              toastr.error(error.response.data.errors.join());
            })
      } else {
        if(!this.validEmail(this.mail)) {
          this.errorMail = true;
        }
        if(!this.phone) {
          this.errorPhone = true;
        }

      }
    },*/
    sendForm: function () {

      //this.validEmail(this.mail);
      //console.log(this.validEmail(this.mail));
      //if(this.phone || this.validEmail(this.mail)) {
      axios.put(`/api/v1/rottamagratis/request/${this.idCaricamento}`, {
        email: this.mail,
        telefono: this.phone,
        citta: this.town,
        provincia: this.area,
        message: this.textMessage,
        referrer: this.referrer,
        targa: this.targa,
      })
          .then(() => {
            this.mail = '';
            this.phone = '';
            this.town = '';
            this.area = '';
            this.textMessage = '';
            this.checkboxModel = false;
            this.sendSuccess = true;
            window.trackFormSubmit('rottama-gratis-step2', window.location.href)
            this.isLoading = false;
            this.errorPhone = false;
            this.errorMail = false;
          })
          .catch(error => {
            toastr.error(error.response.data.errors.join());
          })
      //} else {
      //if(!this.validEmail(this.mail)) {
      // this.errorMail = true;
      // }
      // if(!this.phone) {
      //this.errorPhone = true;
      //}

      //}
    },
    cercaTarga: function () {
      if (this.targa) {
        if (!this.targa.match(/^[a-zA-Z]{2,3}[0-9A-Za-z]{4,6}$/)) {
          CustomSwal({
            title: "Errore",
            text: "Targa non valida",
            confirmButtonText: "Torna al sito",
            icon: "error",
          });
          return;
        } else if (grecaptcha && grecaptcha.getResponse().length === 0) {
          CustomSwal({
            title: "Errore",
            html: 'Codice di verifica non compilato, spuntare la casella <em>"Non sono un robot"</em>',
            confirmButtonText: "Torna al sito",
            icon: "error",
          });
          return;
        }
        console.log(grecaptcha.getResponse());
        axios.get('/api/v1/rottamagratis/targa', {
          params: {
            targa: this.targa,
            captcha: grecaptcha.getResponse()
          },
        })
            .then(response => {
              const result = response.data.data[0];
              this.reloadingData = true;
              this.selectedBrand = result.idMar;
              $("#brand_select").val(this.selectedBrand);
              $("#brand_select").trigger('change');
              this.$emit("changeBrand", this.selectedBrand);
              this.selectedModel = result.idMod;
              this.selectedDate = result.immatricolazione;
              $("#date_select").removeAttr("disabled");
            })
            .catch(error => {
              toastr.error(error.response.data.errors.join());
            })
        // return axios
        //     .request({
        //         method: "GET",
        //         url:
        //         "http://www.rottamagratis.com/request.php?operazione=cercatarga&targa=" + this.targa + "&captcha=" +  grecaptcha.getResponse(),
        //     })
        //     .then((response) => {
        //       if(!response.data.success) {
        //         toastr.error(response.data.msg, "Errore");
        //         return;
        //       }
        //       this.reloadingData = true;
        //       this.selectedBrand = response.data.msg.idMar;
        //       $("#brand_select").val(this.selectedBrand);
        //       $("#brand_select").trigger('change');
        //       this.$emit("changeBrand", this.selectedBrand);
        //       this.selectedModel = response.data.msg.idMod;
        //       this.selectedDate = response.data.msg.immatricolazione;
        //       $("#date_select").removeAttr("disabled");
        //       /*this.loadModels();
        //       this.loadYears();*/
        //     })
        //     .catch((error) => {
        //         toastr.error('Impossibile contattare il server', "Errore");
        //     });
      }
    },
    validEmail: function (email) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return true;
      } else {
        this.errorMail = true;
      }
    },
  }
}
</script>
<style scoped>
.input-form-rottama {
  margin-top: 10px;
}

div textarea.textarea-form-rottama {
  height: 100px;
  margin-top: 10px;
}

.error-message {
  color: #c71a20;
}

.input-group .input-group-append button {
  position: relative;
  z-index: 2;
  background-color: #d81e1e;
  outline: none;
  border: 1px solid #d81e1e;
  color: #fff;
  margin-top: 10px;
}

.input-group .input-group-append {
  margin-left: -1px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.contatti .input-group input {
  position: relative;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1% !important;
  margin-bottom: 0 !important;
  text-transform: uppercase;
}

.contatti .input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 100%;
}

.success-message {
  padding: 30px 50px;
  border: 2px solid green;
}

.custom-space-h5 {
  margin-bottom: 20px;
  margin-top: 20px;
}

.margin-top-custom {
  margin-top: 10px;
}

.button-div {
  padding-right: 0;
}

.car-value {
  color: #d81e1e;
}

.big-contact {
  color: #262626;
}

.small-contact {
  font-size: 16px;
  color: #6c757d;

}

.whatsapp__box {
  width: fit-content;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 30px;
}

.icona-whatsapp {
  width: 40px;
  margin-right: 10px;
}

@media screen and (max-width: 992px) {
  .big-contact {
    font-size: 16px;
  }
}
</style>
