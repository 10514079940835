<template>
  <form class="ricerca-pneumatici" id="tires-search-form">
    <div class="d-flex">
      <div class="flex-grow-1">
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            class="form-control"
            id="width-input"
            required
            v-model="selectedWidth"
          >
            <option :value="null">
              {{ $trans("filter-model.larghezza") }} (nnn) *
            </option>
            <option
              :value="width.valore_attributo"
              v-for="(width, index) in widths"
              :key="`${width.valore_attributo}${index}`"
            >
              {{ width.valore_attributo }}
            </option>
          </select>
          <div class="invalid-feedback text-left">
            {{ $trans("filter-model.selezionare-larghezza") }}
          </div>
        </div>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            class="form-control"
            id="height-input"
            required
            v-model="selectedHeight"
          >
            <option :value="null">
              {{ $trans("filter-model.altezza") }} (nn) *
            </option>
            <option
              :value="height.valore_attributo"
              v-for="(height, index) in heights"
              :key="`${height.valore_attributo}${index}`"
            >
              {{ height.valore_attributo }}
            </option>
          </select>
          <div class="invalid-feedback text-left">
            {{ $trans("filter-model.selezionare-altezza") }}
          </div>
        </div>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            class="form-control"
            id="radius-input"
            required
            v-model="selectedRadius"
          >
            <option :value="null">{{ $trans("filter-model.raggio") }} *</option>
            <option
              :value="radius.valore_attributo"
              v-for="(radius, index) in rays"
              :key="`${radius.valore_attributo}${index}`"
            >
              {{ radius.valore_attributo }}
            </option>
          </select>
          <div class="invalid-feedback text-left">
            {{ $trans("filter-model.selezionare-raggio") }}
          </div>
        </div>
        <div
          class="block-finder__form-control block-finder__form-control--select"
        >
          <select
            class="form-control"
            id="type-input"
            v-model="selectedType"
          >
            <option :value="null">
              {{ $trans("filter-model.tipologia-pneumatico") }}
            </option>
            <option
              :value="type.valore_attributo"
              v-for="(type, index) in types"
              :key="`${type.valore_attributo}${index}`"
            >
              {{ type.valore_attributo }}
            </option>
          </select>
          <div class="invalid-feedback text-left">
            {{ $trans("filter-model.selezionare-tipo") }}
          </div>
        </div>
        <div
          class="form-row"
          v-bind="this.searchTiresIssue"
          v-if="this.searchTiresIssue"
        >
          <span class="text-danger">{{ this.searchTiresIssue }}</span>
        </div>
      </div>
      <button
        class="
          btn btn-primary
          block-finder__form-control block-finder__form-control--button
        "
        type="button"
        v-bind:disabled="!isValid"
        v-on:click="submitForm()"
      > {{ $trans("common.cerca") }}
      </button>
    </div>
  </form>
</template> 

<script>
import axios from "axios";
import toastr from "toastr";

export default {
  name: "filter-tires",
  props: {
    route: String,
    searchTiresIssue: {
      required: false,
      type: String,
      default: 0,
    },
  },
  data() {
    return {
      widths: [],
      heights: [],
      rays: [],
      types: [],
      selectedWidth: null,
      selectedHeight: null,
      selectedRadius: null,
      selectedType: null,
    };
  },
  mounted() {
    console.log('qui', this.route);
    const self = this;
    $("#width-input").select2({ language: "it" });
    $("#width-input").on("change", function () {
      self.selectedWidth = this.value;
    });

    $("#height-input").select2({ language: "it" });
    $("#height-input").on("change", function () {
      self.selectedHeight = this.value;
    });

    $("#radius-input").select2({ language: "it" });
    $("#radius-input").on("change", function () {
      self.selectedRadius = this.value;
    });

    $("#type-input").select2({ language: "it" });
    $("#type-input").on("change", function () {
      self.selectedType = this.value;
    });
  },
  created() {
    this.loadAttributes();
  },
  methods: {
    loadAttributes: function () {
      return axios
        .request({
          method: "GET",
          url: "/api/v1/attribute/tires",
        })
        .then((response) => {
          var data = response.data.data[0];
          this.$set(this, "widths", data[0].values);
          this.$set(this, "heights", data[1].values);
          this.$set(this, "rays", data[2].values);
          this.$set(this, "types", data[3].values);
          this.$set(this, "selectedWidth", null);
          this.$set(this, "selectedHeight", null);
          this.$set(this, "selectedRadius", null);
          this.$set(this, "selectedType", null);
        })
        .catch((error) => {
          toastr.error(error.message, "Errore");
        });
    },
    submitForm: function () {
      if (this.isValid) {
        let _route = this.route
            .replace("pl1", this.selectedWidth.replace('.', '__'))
            .replace("pl2", this.selectedHeight.replace('.', '__'))
            .replace("pl3", this.selectedRadius.replace('.', '__'))
            .replace("pl4", this.selectedType ? this.selectedType.replace('.', '__') : '');
        if(_route.charAt(_route.length - 1) === '-'){
          _route = _route.substr(0, _route.length - 1);
        }
        window.location.href = _route;
        // window.location.href =
        //   "/pneumatici-auto-" +
        //   this.selectedWidth +
        //   "-" +
        //   this.selectedHeight +
        //   "-" +
        //   this.selectedRadius +
        //   "-" +
        //   this.selectedType;
      }
    },
  },
  computed: {
    isValid: function () {
      return (
        this.selectedWidth &&
        this.selectedHeight &&
        this.selectedRadius
        // && this.selectedType
      );
    },
  },
};
</script>

<style scoped>
</style>
