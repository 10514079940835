<template>
  <form @submit="search" method="GET" v-bind:action="formUrl">
    <div class="targa__search block-finder__form-control d-flex">
      <div class="license-wrapper mr-1 flex-grow-1" data-country="it">
        <input
          class="not-found__search-input form-control"
          name="license_number"
          placeholder="AB 123 CD"
          type="text"
          v-model="licenseNumber"
        />
      </div>
      <button class="btn btn-primary" type="submit" v-bind:disabled="!isValid">
        {{ $trans("common.cerca") }}
      </button>
    </div>
    <div class="form-row">
      <div
        class="g-recaptcha text-center mt-2"
        style="margin: auto"
        v-bind:data-sitekey="recaptchaSiteKey"
      ></div>
    </div>
    <div class="form-row mt-3 px-3 d-flex justify-content-between">
      <button class="btn btn-primary" onclick="document.querySelector('#tires-tab').click()">{{ $trans("common.gomme_usate") }}</button>
      <a class="btn btn-primary" href="https://gomme.polliniapp.it/" target="_blank">{{ $trans("common.gomme_nuove") }}</a>
    </div>
  </form>
</template>

<script>
import { CustomSwal } from "../utils";

export default {
  name: "filter-license-number",
  props: {
    formUrl: String,
    recaptchaSiteKey: String,
  },
  data() {
    return {
      licenseNumber: null,
    };
  },
  methods: {
    search: function (e) {
      e.preventDefault();
      // if (!this.licenseNumber.match(/[a-zA-Z]{2}[0-9]{3,4}[a-zA-Z]{2}/)) {
      if (!this.licenseNumber.match(/^[a-zA-Z]{2,3}[0-9A-Za-z]{4,6}$/)) {
        CustomSwal({
          title: "Errore",
          text: "Targa non valida",
          confirmButtonText: "Torna al sito",
          icon: "error",
        });
        return;
      } else if (grecaptcha && grecaptcha.getResponse().length === 0) {
        CustomSwal({
          title: "Errore",
          html: 'Codice di verifica non compilato, spuntare la casella <em>"Non sono un robot"</em>',
          confirmButtonText: "Torna al sito",
          icon: "error",
        });
        return;
      }
      console.log(grecaptcha.getResponse());
      e.currentTarget.submit();
    },
  },
  computed: {
    isValid: function () {
      return this.licenseNumber;
    },
  },
};
</script>
