import { render, staticRenderFns } from "./rottama-gratis.vue?vue&type=template&id=adadb36a&scoped=true&"
import script from "./rottama-gratis.vue?vue&type=script&lang=js&"
export * from "./rottama-gratis.vue?vue&type=script&lang=js&"
import style0 from "./rottama-gratis.vue?vue&type=style&index=0&id=adadb36a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adadb36a",
  null
  
)

export default component.exports